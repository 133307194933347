import React from 'react';
import { Typography, Grid, Icon } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ReservationButton from "../Components/ReservationButton";

// Import payment method logos
import VisaLogo from '../visa.png';
import MastercardLogo from '../mastercard.png';
import PaypalLogo from '../paypal.png';
import AmexLogo from '../amex.png';
import GpayLogo from '../gpay.png';
import ApplePayLogo from '../apay.png';

// Import necessary icons from '@mui/icons-material'
import PowerIcon from '@mui/icons-material/Power';
import LocalDrinkIcon from '@mui/icons-material/Liquor';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import StormIcon from '@mui/icons-material/Storm';

const useStyles = makeStyles((theme) => ({
    equipementItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        textAlign: 'center',
    },
    title: {
        fontWeight: 'bold',
    },
    icon: {
        fontSize: 48,
        marginTop: '5px',
    },
    container: {
        marginTop: '20px',
        marginBottom: '30px',
    },
    paymentLogo: {
        maxWidth: '60px',
        margin: '5px',
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '50px',
        },
    },
    paymentGridItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const EquipementItem = ({ title, icon }) => {
    const classes = useStyles();
    return (
        <Grid item xs={6} sm={4} md={3} className={classes.paymentGridItem}>
            <div className={classes.equipementItem}>
                <Icon className={classes.icon}>{icon}</Icon>
                <Typography variant="body2" className={classes.title}> {/* Réduction de la taille du texte */}
                    {title}
                </Typography>
            </div>
        </Grid>
    );
};

export function Prestations() {
    const classes = useStyles();

    const equipements = [
        { title: 'Chargeur', icon: <PowerIcon /> },
        { title: 'Rafraîchissement', icon: <LocalDrinkIcon /> },
        { title: 'Gel hydroalcoolique', icon: <SanitizerIcon /> },
        { title: 'Friandises', icon: <StormIcon /> },
    ];

    const paymentMethods = [
        { name: 'Visa', logo: VisaLogo },
        { name: 'Mastercard', logo: MastercardLogo },
        { name: 'PayPal', logo: PaypalLogo },
        { name: 'American Express', logo: AmexLogo },
        { name: 'Apple Pay', logo: ApplePayLogo },
        { name: 'Google Pay', logo: GpayLogo },
    ];

    return (
        <>
            <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '20px' }}>
                Nos prestations à Nantes
            </Typography>

            <Typography variant="body1" paragraph>
                Chez {process.env.REACT_APP_BUSINESS_NAME}, nous garantissons une expérience exceptionnelle lors de chaque déplacement.
            </Typography>

            <ReservationButton buttonText="Réserver" phoneNumber={process.env.REACT_APP_BUSINESS_PHONE} />

            <Typography variant="h5" style={{ textAlign: 'center', marginTop: '20px' }}>
                Équipements à bord
            </Typography>
            {/* Réduire l'espacement entre EquipementItem */}
            <Grid container spacing={1} justifyContent="center" className={classes.container}>
                {equipements.map((service, index) => (
                    <EquipementItem key={index} title={service.title} icon={service.icon} />
                ))}
            </Grid>

            <Typography variant="h5" style={{ textAlign: 'center', marginTop: '20px' }}>
                Moyens de paiement acceptés
            </Typography>

            {/* Réduire l'espacement entre paymentMethods */}
            <Grid container spacing={1} justifyContent="center">
                {paymentMethods.map((method, index) => (
                    <Grid item xs={6} sm={4} md={3} key={index} className={classes.paymentGridItem}>
                        <img src={method.logo} alt={method.name} className={classes.paymentLogo} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
