import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Button, Toolbar, Typography, IconButton, Menu, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../logo.svg'; // Assurez-vous d'importer le logo ici
import '../Styles/navbar.css';

export function Navbar() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [menuAnchor, setMenuAnchor] = useState(null);

    const handleMenuOpen = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const navLinks = (
        <>
            <Link to="/Apropos" className="nav-link" onClick={handleMenuClose}><Button className="nav-button">À propos</Button></Link>
            <Link to="/Prestations" className="nav-link" onClick={handleMenuClose}><Button className="nav-button">Prestations</Button></Link>
            <Link to="/Tarifs" className="nav-link" onClick={handleMenuClose}><Button className="nav-button">Tarifs</Button></Link>
            <Link to="/Reserver" className="nav-link" onClick={handleMenuClose}><Button className="nav-button">Réserver</Button></Link>
            <Link to="/Contact" className="nav-link" onClick={handleMenuClose}><Button className="nav-button">Contact</Button></Link>
        </>
    );

    return (
        <>
            {isMobile ? (
                <>
                    <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleMenuOpen}
                        className="menu-button" /* Cette classe utilise les styles CSS ci-dessus */
                    >
                        <MenuIcon className={'icon-menu'} />
                    </IconButton>

                    <Menu
                        id="menu"
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}
                        onClose={handleMenuClose}
                        className="mobile-menu"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        {navLinks}
                    </Menu>
                    <div
                        className={`overlay ${menuAnchor ? 'show' : ''}`}
                        onClick={handleMenuClose}
                    ></div>
                </>
            ) : (
                <AppBar className="navbar">
                    <Toolbar className="toolbar-content">
                        {/* Ajout du logo à gauche */}
                        <Link to="/" className="logo-container-navbar">
                            <img src={logo} alt="Logo" className="navbar-logo" />
                            <Typography variant="h6" component="div" className="nav-title">
                                Sûr et convivial
                            </Typography>
                        </Link>

                        {navLinks}
                    </Toolbar>
                </AppBar>
            )}
        </>
    );
}

export default Navbar;
