import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Reserver } from './Pages/Reserver.js';
import { Apropos } from './Pages/Apropos.js';
import { Prestations } from './Pages/Prestations.js';
import { Tarifs } from './Pages/Tarifs.js';
import { Contact } from './Pages/Contact.js';
import { MentionsLegales } from './Pages/Mentions.js';
import { ConditionsGeneralesDeVentes } from './Pages/ConditionsGeneralesDeVentes.js';
import 'react-datepicker/dist/react-datepicker.css';
import backgroundImg1 from './nantes3.jpg';
import backgroundImg2 from './vueducielnantes.jpg';
import backgroundImg3 from './elephant.jpg';
import backgroundImg4 from './vuegareciel.jpg';
import { Layout } from "./Components/Layout";
import { Aeroport } from "./Pages/Aeroport";
import { Gare } from "./Pages/Gare";
import { Tourisme } from "./Pages/Tourisme";
import { Plage } from "./Pages/Plage";
import { Business } from "./Pages/Business";
import { Hotel } from "./Pages/Hotel";
import { LonguesDistances } from "./Pages/LonguesDistances";
import { Mariage } from "./Pages/Mariage";
import { Femme } from "./Pages/Femme";
import { Voyage } from "./Pages/Voyage";
import { RestaurantsClubs } from "./Pages/RestaurantsClubs";
import { Panne } from "./Pages/Panne";
import { TransportScolaire } from "./Pages/TransportScolaire";
import { TransportTPMR } from "./Pages/TransportTPMR";
import { ParcAttraction } from "./Pages/ParcAttraction";

const App = () => {
    const [backgroundPosition, setBackgroundPosition] = useState('0% 0%');
    const [backgroundSize, setBackgroundSize] = useState('120%');
    const [backgroundImg, setBackgroundImg] = useState('');
    const [previousIndex, setPreviousIndex] = useState(-1);

    const location = useLocation();

    useEffect(() => {
        const interval = setInterval(() => {
            setBackgroundPosition((prevPosition) => {
                const [x, y] = prevPosition.split(' ').map(parseFloat);
                const newX = (x + 0.3) % 100;
                const newY = (y + 0.0) % 100;
                return `${newX}% ${newY}%`;
            });
        }, 100);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1024) {
                setBackgroundSize('300%'); // Ajustez cette valeur pour les écrans mobiles
            } else {
                setBackgroundSize('120%');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Appel initial pour définir la taille correcte

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const changeBackgroundImage = () => {
            const images = [backgroundImg1, backgroundImg2, backgroundImg3, backgroundImg4];
            let randomIndex;

            do {
                randomIndex = Math.floor(Math.random() * images.length);
            } while (randomIndex === previousIndex);

            setBackgroundImg(images[randomIndex]);
            setPreviousIndex(randomIndex);
        };

        changeBackgroundImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <div
            style={{
                backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url(${backgroundImg})`,
                backgroundSize: backgroundSize,
                backgroundPosition: backgroundPosition,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                transition: 'background-position 0.1s ease',
            }}
        >
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/" element={<Tarifs />} />
                    <Route path="/Apropos" element={<Apropos />} />
                    <Route path="/Prestations" element={<Prestations />} />
                    <Route path="/Tarifs" element={<Tarifs />} />
                    <Route path="/Reserver" element={<Reserver />} />
                    <Route path="/Contact" element={<Contact />} />
                    <Route path="/mentions-legales" element={<MentionsLegales />} />
                    <Route path="/condtions-generales-de-ventes" element={<ConditionsGeneralesDeVentes />} />
                    <Route path="/aeroport" element={<Aeroport />} />
                    <Route path="/gare" element={<Gare />} />
                    <Route path="/tourisme" element={<Tourisme />} />
                    <Route path="/plage" element={<Plage />} />
                    <Route path="/business" element={<Business />} />
                    <Route path="/hotel" element={<Hotel />} />
                    <Route path="/longues-distances" element={<LonguesDistances />} />
                    <Route path="/mariage" element={<Mariage />} />
                    <Route path="/femme" element={<Femme />} />
                    <Route path="/voyages" element={<Voyage />} />
                    <Route path="/restaurants-clubs" element={<RestaurantsClubs />} />
                    <Route path="/panne" element={<Panne />} />
                    <Route path="/transport-scolaire" element={<TransportScolaire />} />
                    <Route path="/transport-tpmr" element={<TransportTPMR />} />
                    <Route path="/parcs-attractions" element={<ParcAttraction />} />
                </Route>
            </Routes>
        </div>
    );
};

const AppWrapper = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;
